import revive_payload_client_6SKwPgknUw from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4FWHTwHwhn from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PXEHwnAbn2 from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lcaUETiCYs from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_blXwAelJ3u from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7Hx2aXbfSZ from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fmOWJZ60oM from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/igor/Sites/platform/apps/console/web/.nuxt/components.plugin.mjs";
import prefetch_client_xu2lhtq3DF from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_fxr5QpR3De from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_syGloDX93i from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt-csurf@1.6.5_magicast@0.3.5/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import fontawesome_cn2c4tOOHz from "/Users/igor/Sites/platform/apps/console/web/plugins/fontawesome.ts";
import posthog_client_fUucxKWhZE from "/Users/igor/Sites/platform/apps/console/web/plugins/posthog.client.ts";
export default [
  revive_payload_client_6SKwPgknUw,
  unhead_4FWHTwHwhn,
  router_PXEHwnAbn2,
  payload_client_lcaUETiCYs,
  navigation_repaint_client_blXwAelJ3u,
  check_outdated_build_client_7Hx2aXbfSZ,
  chunk_reload_client_fmOWJZ60oM,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xu2lhtq3DF,
  plugin_client_fxr5QpR3De,
  plugin_syGloDX93i,
  fontawesome_cn2c4tOOHz,
  posthog_client_fUucxKWhZE
]