
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indextyzGoLafvvMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/index.vue?macro=true";
import { default as catalog_45dataou89wRlKckMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/catalog-data.vue?macro=true";
import { default as customersQCCeUDbP7IMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/customers.vue?macro=true";
import { default as eventsJHdxT4f3zKMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/events.vue?macro=true";
import { default as indexD85juAvAuAMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/index.vue?macro=true";
import { default as integrationsXSoBKmINkwMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/integrations.vue?macro=true";
import { default as editTSMKzp26e0Meta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/edit.vue?macro=true";
import { default as indexEIBvQLn0TKMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/index.vue?macro=true";
import { default as indexKUeTgDbJsSMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/index.vue?macro=true";
import { default as retrainE7n3LAjdMOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/retrain.vue?macro=true";
import { default as web_45tagsu6WSniExDtMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/web-tags.vue?macro=true";
import { default as indexHEQw3sE8geMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/abandoned-cart/index.vue?macro=true";
import { default as _2faa3k4L6lPWOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/2fa.vue?macro=true";
import { default as indexe6ebIAPGlOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/index.vue?macro=true";
import { default as _91reset_password_code_93xedZqEyxcOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/reset-password/[reset_password_code].vue?macro=true";
import { default as setuphQC2ZW5uRjMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/setup.vue?macro=true";
import { default as indexCgOsIPEsgmMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/index.vue?macro=true";
import { default as indexiCzdgyvJ1yMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/login/index.vue?macro=true";
import { default as indexy8iCKb0HixMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/org-[org_id]/billing/index.vue?macro=true";
import { default as indexe4gQFuups9Meta } from "/Users/igor/Sites/platform/apps/console/web/pages/register/index.vue?macro=true";
import { default as indexowMoRi0FpHMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/support/index.vue?macro=true";
export default [
  {
    name: "project",
    path: "/:project()",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/index.vue")
  },
  {
    name: "project-ml-catalog-data",
    path: "/:project()/ml/catalog-data",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/catalog-data.vue")
  },
  {
    name: "project-ml-customers",
    path: "/:project()/ml/customers",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/customers.vue")
  },
  {
    name: "project-ml-events",
    path: "/:project()/ml/events",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/events.vue")
  },
  {
    name: "project-ml",
    path: "/:project()/ml",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/index.vue")
  },
  {
    name: "project-ml-integrations",
    path: "/:project()/ml/integrations",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/integrations.vue")
  },
  {
    name: "project-ml-models-model_id-edit",
    path: "/:project()/ml/models/:model_id()/edit",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/edit.vue")
  },
  {
    name: "project-ml-models-model_id",
    path: "/:project()/ml/models/:model_id()",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/index.vue")
  },
  {
    name: "project-ml-models",
    path: "/:project()/ml/models",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/index.vue")
  },
  {
    name: "project-ml-retrain",
    path: "/:project()/ml/retrain",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/retrain.vue")
  },
  {
    name: "project-ml-web-tags",
    path: "/:project()/ml/web-tags",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/web-tags.vue")
  },
  {
    name: "abandoned-cart",
    path: "/abandoned-cart",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/abandoned-cart/index.vue")
  },
  {
    name: "account-2fa",
    path: "/account/2fa",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/2fa.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/index.vue")
  },
  {
    name: "account-reset-password-reset_password_code",
    path: "/account/reset-password/:reset_password_code()",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/reset-password/[reset_password_code].vue")
  },
  {
    name: "account-setup",
    path: "/account/setup",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/setup.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/login/index.vue")
  },
  {
    name: "org-org_id-billing",
    path: "/org-:org_id()/billing",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/org-[org_id]/billing/index.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/register/index.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/support/index.vue")
  }
]