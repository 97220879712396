export default defineNuxtRouteMiddleware(async (to, from) =>
{
	const url = to.params.org_id
	if (!url)
		return
	
	const { data } = await useFetch('/api/organizations/' + url)
	if (!data || !data.value)
		return
	
	const store = useConsoleStore()
	store.organization = data.value
})