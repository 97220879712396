import validate from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/igor/Sites/platform/apps/console/web/middleware/auth.global.ts";
import org_45global from "/Users/igor/Sites/platform/apps/console/web/middleware/org.global.ts";
import project_45global from "/Users/igor/Sites/platform/apps/console/web/middleware/project.global.ts";
import manifest_45route_45rule from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_ioredis@5.5.0_magicast@_8f3dba92ab9a7d1756b1346eeab6bb00/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  org_45global,
  project_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}