export default defineNuxtRouteMiddleware(async (to, from) =>
{
	// const store = useConsoleStore()
	
	const user = useUser()
	
	// Not using useCsrfFetch because it's not working with local urls
	const r = await useFetch('/api/auth/user', {
		key: 'auth-user',
		cache: 'no-cache',
	})
	const data = r.data
	// console.log('Middleware running on:', import.meta.client ? 'client' : 'server', r.data.value)
	
	if (data?.value?.user)
	{
		user.value = data.value.user
		return
	}
	
	if (
			to.path != '/login/'
		&&	to.path != '/register/'
		&&	!to.path.startsWith('/account/reset-password')
		&&	!to.path.startsWith('/services-api/')
	)
	{
		return navigateTo('/login/')
	}
})